import type { ISbStoriesParams, StoryblokBridgeConfigV2 } from '@storyblok/vue'
import type { StoryblokVersion } from '~/utils/types/storyblok/enums'

export const useStoryblokDealerMe = async (
    params: StoryBlokParams,
    isStoryblokApiCall: boolean = false,
) => {
    const storyblokStore = useStoryblokStore()
    const { locale } = useI18n()

    const slug: string = params.slug ?? ''
    const bridgeOptions: StoryblokBridgeConfigV2 = params.bridgeOptions ?? {}
    const version: StoryblokVersion | null = params.version ?? null
    const language: string | null = params.language ?? null

    const apiOptions = {
        ...{
            version: version ?? storyblokStore.version,
            language: language ?? locale.value,
        },
        ...(params.apiOptions ?? {}),
    }

    if (isStoryblokApiCall) {
        return await useStoryblokApi().get('cdn/stories', apiOptions)
    } else {
        return await useAsyncStoryblok(
            slug,
            {
                ...{
                    version: version ?? storyblokStore.version,
                    language: language ?? locale.value,
                },
                ...apiOptions,
            },
            bridgeOptions,
        )
    }
}

interface StoryBlokParams {
    slug?: string | undefined
    apiOptions?: ISbStoriesParams | undefined
    bridgeOptions?: StoryblokBridgeConfigV2 | undefined
    version?: StoryblokVersion | undefined
    language?: string | undefined
}
